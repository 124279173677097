
import cmsBlock from '~/mixins/cmsBlock'

export default {
  name: 'CmsMediclub3Block4',
  mixins: [cmsBlock],

  data () {
    return {
      fallbackContent: {
        title: 'Comprehensive thematic areas',
        subtitle: 'only for MediClub Members',
        joinMediclubSubtext: 'and be well feel good enjoy your life',
        items: [
          {
            title: 'Mediclub',
            bg: '/mc/v3/block4/bg/MediClub.png',
            desc: [
              'Medical services at your fingertips',
              'Comprehensive offer',
              'Attractive discounts and benefits',
              'Access to thousands of specialists and services',
              'permanent discounts (up to 60%)',
            ],

            to: '/opcjonalny/url',
          },
          {
            title: 'Mental Health',
            ico: '/mc/v3/block4/ico/mentalHealth.svg',
            bg: '/mc/v3/block4/bg/Mental.png',
            desc: [
              'Specialist <b>consultations</b>',
            ],
          },
          {
            title: 'Image',
            ico: '/mc/v3/block4/ico/Image.svg',
            bg: '/mc/v3/block4/bg/Image.png',
            desc: [
              'Aesthetic <b>medicine doctor</b>',
              'Facial endermology',
            ],
          },
          {
            title: 'Prevention',
            ico: '/mc/v3/block4/ico/Prevention.svg',
            bg: '/mc/v3/block4/bg/Prevention.png',
            desc: [
              '<b>Consultation</b> with a sports medicine doctor',
              'Comprehensive Health Analysis',
              'Orthopaedist',
              'Sports <b>medicine</b> doctor',
            ],
          },
          {
            title: 'Healthy Maternity',
            ico: '/mc/v3/block4/ico/healthyMaternity.svg',
            bg: '/mc/v3/block4/bg/Maternity.png',
            desc: [
              'Non-invasive <b>prenatal examination</b>',
              'Hospital Package',
            ],
          },
          {
            title: 'Treatment',
            ico: '/mc/v3/block4/ico/treatment.svg',
            bg: '/mc/v3/block4/bg/Treatment.png',
            desc: [
              '<b>Qualification visit</b> - Medical marijuana therapy',
            ],
          },
          {
            title: 'Healthy Nutrition',
            ico: '/mc/v3/block4/ico/healthyNutrition.svg',
            bg: '/mc/v3/block4/bg/Nutrition.png',
            desc: [
              '<b>NutriFit</b> - Genetic testing for diet and health',
              'Dietitian consultation',
            ],
          },
          {
            title: 'Physical Activity',
            ico: '/mc/v3/block4/ico/physicalActivity.svg',
            bg: '/mc/v3/block4/bg/Physical.png',
            desc: [
              '5000 facilities',
              'Access to thousands of clubs throughout Poland',
              'Free coaching consultation to start',
              'High-quality training equipment',
            ],
          },
          {
            title: 'For Kids',
            ico: '/mc/v3/block4/ico/for-kids.svg',
            bg: '/mc/v3/block4/bg/Kids.png',
            desc: [
              'Paediatrician ',
              'Profile - <b>Healthy Child</b>',
            ],
          },
        ],
      },
    }
  },

  methods: {
    getComponent (url) {
      return typeof url === 'string' && url[0] === '/' ? 'NuxtLink' : 'div'
    },
  },
}
